import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/bin/staticsites/ss-oryx/app-int/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import HomepageTemplate from 'gatsby-theme-carbon/src/templates/Homepage';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const FeatureCard = makeShortcode("FeatureCard");
const ArtDirection = makeShortcode("ArtDirection");
const layoutProps = {
  _frontmatter
};
const MDXLayout = HomepageTemplate;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h2>{`MDX content starts here`}</h2>
    <PageDescription mdxType="PageDescription">
      <p>{`The homepage content here lives in the `}<inlineCode parentName="p">{`src/pages/index.mdx`}</inlineCode>{` directory at the root of your project. Just like the other mdx pages, you can use all of our theme components here without importing them. Review the `}<a parentName="p" {...{
          "href": "https://github.com/carbon-design-system/gatsby-theme-carbon/blob/master/packages/example/src/pages/index.mdx"
        }}>{`MDX example source`}</a>{`.`}</p>
    </PageDescription>
    <FeatureCard color="dark" href="/getting-started" title="Getting started" actionIcon="arrowRight" className="homepage-feature" mdxType="FeatureCard">
      <ArtDirection mdxType="ArtDirection">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAAApElEQVQoz+XSMQ4BURSF4SeZp1AqTGUJNiBKldoKZgMapQWwAp3SFpQa1qCYXqmhMBHnd6abCAmRqdwFfPmTe0IIQY1mqtgeKkkzJd2ZYm+t2N8qjnK1srM6UzSYS+MlmqzRYoNWe7TL0eGIThd0vVHe3V7AIAYxSAXEIAYxiEEMYhCDGMQgBinBogJWC5/BjwuLF4XvwK8L/xCs7Sm1zObnYT8ACmQHKavKNk4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "blue vertical stripes",
            "title": "blue vertical stripes",
            "src": "/static/0662e09c0b7fcc0ee0e7b8e912f8aa7c/3cbba/blue-stripes-mobile.png",
            "srcSet": ["/static/0662e09c0b7fcc0ee0e7b8e912f8aa7c/7fc1e/blue-stripes-mobile.png 288w", "/static/0662e09c0b7fcc0ee0e7b8e912f8aa7c/a5df1/blue-stripes-mobile.png 576w", "/static/0662e09c0b7fcc0ee0e7b8e912f8aa7c/3cbba/blue-stripes-mobile.png 1152w", "/static/0662e09c0b7fcc0ee0e7b8e912f8aa7c/362ee/blue-stripes-mobile.png 1600w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAAApElEQVQoz+XSMQ4BURSF4SeZp1AqTGUJNiBKldoKZgMapQWwAp3SFpQa1qCYXqmhMBHnd6abCAmRqdwFfPmTe0IIQY1mqtgeKkkzJd2ZYm+t2N8qjnK1srM6UzSYS+MlmqzRYoNWe7TL0eGIThd0vVHe3V7AIAYxSAXEIAYxiEEMYhCDGMQgBinBogJWC5/BjwuLF4XvwK8L/xCs7Sm1zObnYT8ACmQHKavKNk4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "blue vertical stripes",
            "title": "blue vertical stripes",
            "src": "/static/714db3c141e2e35bc62de90825ac2e72/3cbba/blue-stripes-tablet.png",
            "srcSet": ["/static/714db3c141e2e35bc62de90825ac2e72/7fc1e/blue-stripes-tablet.png 288w", "/static/714db3c141e2e35bc62de90825ac2e72/a5df1/blue-stripes-tablet.png 576w", "/static/714db3c141e2e35bc62de90825ac2e72/3cbba/blue-stripes-tablet.png 1152w", "/static/714db3c141e2e35bc62de90825ac2e72/362ee/blue-stripes-tablet.png 1600w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsSAAALEgHS3X78AAAAd0lEQVQoz2NgZBH6z8Lv+J9ZOPY/k1jlfwaluf8ZdLb9Z7C/9p/B99l/hoz//2Ur//236fz3P3r2//+Fy///79n1///C4///H7/z//+N5///v/vy//+P3//BgIEBaiCTUOx/RtEK7AZWkGAg3IXUMpBh1EBKDQQA2ftyUuvMYaUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "blue vertical stripes",
            "title": "blue vertical stripes",
            "src": "/static/94d00b5a50f32d01224183239bc5c4af/3cbba/blue-stripes.png",
            "srcSet": ["/static/94d00b5a50f32d01224183239bc5c4af/7fc1e/blue-stripes.png 288w", "/static/94d00b5a50f32d01224183239bc5c4af/a5df1/blue-stripes.png 576w", "/static/94d00b5a50f32d01224183239bc5c4af/3cbba/blue-stripes.png 1152w", "/static/94d00b5a50f32d01224183239bc5c4af/0b124/blue-stripes.png 1728w", "/static/94d00b5a50f32d01224183239bc5c4af/e957c/blue-stripes.png 1920w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </ArtDirection>
    </FeatureCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      